@charset 'UTF-8';

// https://coolors.co/2d2d34-46555d-596f62-7ea16b-c97d60-ad343e-8b5576-5f8cbf-ebebeb-f4f4f9

$raisin-black: #2d2d34;
$charcoal: #46555d;
$feldgrau: #596f62;
$asparagus: #7ea16b;
$copper-crayola: #c97d60;
$cardinal: #ad343e;
$sugar-plum: #8b5576;
$glaucous: #5f8cbf;
$platinum: #ebebeb;
$ghost-white: #f4f4f9;

$color-foreground-black: $raisin-black;
$color-foreground-white: $ghost-white;

$color-background-black: $raisin-black;
$color-background-white: $ghost-white;

$color-primary: $sugar-plum;
$color-secondary: $feldgrau;

$color-link: $glaucous;
$color-border: $platinum;

$color-info: $glaucous;
$color-success: $asparagus;
$color-warning: $copper-crayola;
$color-failure: $cardinal;

$fonts-body: 'Libre Baskerville', serif;
$fonts-header: 'Unica One', -apple-system, 'Segoe UI', system-ui, Roboto, 'Helvetica Neue', sans-serif;
$fonts-monospace: 'SFMono-Regular', 'SF Mono', 'Ubuntu Mono', Consolas, 'DejaVu Sans Mono', Menlo, monospace;

$screen-size-breakpoint: 700px;

html {
  overflow-y: scroll;
}

body {
  color: $color-foreground-black;
  font-family: $fonts-body;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fonts-header;
  font-weight: 400;
  text-transform: uppercase;
}

a {
  color: $color-link;
  font-weight: bold;
  text-decoration: none;
  transition: color .3s ease-in;
  &:hover {
    color: darken($color-link, 10%);
  }
}

.hero {
  align-items: stretch;
  background: $color-primary;
  color: $color-foreground-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.hero > div {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.hero .container {
  text-align: center;
}

.hero h1 {
  font-size: 2rem;
}

.info {
  background: $color-info;
}

.success {
  background: $color-success;
}

.warning {
  background: $color-warning;
}

.failure {
  background: $color-failure;
}

.container {
  flex-grow: 1;
  max-width: calc(#{$screen-size-breakpoint} - 24px);
  margin: 1rem auto 3rem;
  padding: 0 24px;
  position: relative;
}

::selection,
::-moz-selection {
  background: $color-primary;
  color: $color-foreground-white;
  text-shadow: none;
}
